@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Inter";
    font-weight: 400;
    color: var(--primary-black);
  }
}

@font-face {
  font-family: "Inter";
  src: url(assets/fonts/inter.ttf);
}

@layer utilities {
  .no-spin {
    @apply appearance-none;
  }

  /* Chrome, Safari, Edge, Opera */
  .no-spin::-webkit-outer-spin-button,
  .no-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  --secondary-green: #4edf9a;
  --secondary-red: #fb624a;
  --light-green: #00d455;
  --primary-black: #292929;
  --gray: #f3f3f3;
  --light-gray: #d9d9d9;
  --silver-mist: #dedede;
  --stone-gray: #dadada;
  --charcoal-black: #0e0e0e;
}

@media (prefers-color-scheme: dark) {
  :root {
  }
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
